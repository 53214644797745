import axios from 'axios';
import { API_BASE_URL } from './constants';

class APIClient {
  static async get(endpoint, params = {}) {
    try {
      const response = await axios.get(`${API_BASE_URL}${endpoint}`, { params });
      return response.data;
    } catch (error) {
      throw new Error(`Error calling API: ${error.message}`);
    }
  }

  static async post(endpoint, data = {}) {
    try {
      const response = await axios.post(`${API_BASE_URL}${endpoint}`, data);
      return response.data;
    } catch (error) {
      throw new Error(`Error calling API: ${error.message}`);
    }
  }
}

export default APIClient;
