<template>
  <div class="div1" id="header">
    <div class="page-header-content">
      <div class="col main-icon">
        <img src="images/icon_top.svg" />
      </div>
      <div class="dropdown">
        <button
          class="dropbtn sd"
          id="dropdownButton"
          @click="isDropdownVisible = !isDropdownVisible"
        >
          <div class="dropdown-btn-t1 sd">
            <div class="dropdown-btn-line1 sd"></div>
            <div class="dropdown-btn-line2 sd"></div>
            <div class="dropdown-btn-line3 sd"></div>
          </div>
        </button>
        <div
          class="dropdown-content"
          v-if="isDropdownVisible"
          id="dropdownMenu"
        >
          <a
            href="#"
            v-on:click="
              $event.preventDefault();
              goDiv('div14');
            "
            >&emsp;&emsp;料金</a
          >
          <a
            href="#"
            v-on:click="
              $event.preventDefault();
              goDiv('div15');
            "
            >&emsp;&emsp;レンタル</a
          >
          <a
            href="#"
            v-on:click="
              $event.preventDefault();
              goDiv('div18');
            "
            >&emsp;&emsp;初めての方へ</a
          >
          <a
            href="#"
            v-on:click="
              $event.preventDefault();
              goDiv('div19');
            "
            >&emsp;&emsp;レギュレーション</a
          >
          <a
            href="#"
            v-on:click="
              $event.preventDefault();
              goDiv('div20');
            "
            >&emsp;&emsp;フィールドマップ</a
          >
          <a
            href="#"
            v-on:click="
              $event.preventDefault();
              goDiv('access');
            "
            >&emsp;&emsp;アクセス</a
          >
          <a
            href="#"
            v-on:click="
              $event.preventDefault();
              goDiv('div22');
            "
            >&emsp;&emsp;問い合わせ</a
          >
          <a
            href="#"
            v-on:click="
              $event.preventDefault();
              goToNerf();
            "
            >&emsp;&emsp;ナーフ戦</a
          >
          <a
            href="#"
            v-on:click="
              $event.preventDefault();
              goToGallery();
            "
            >&emsp;&emsp;フォトギャラリー</a
          >
          <a href="https://ewc.theshop.jp/" target="_blank"
            >&emsp;&emsp;グッズ販売</a
          >
        </div>
      </div>
      <div class="col header-btn border margin-right-60">
        <p class="header-text" v-on:click="goToLogin">ログイン</p>
      </div>
      <div class="col header-btn border">
        <p class="header-text" v-on:click="goToBooking">予約情報</p>
      </div>
      <div class="col text-only">
        <p class="header-text">TEL 088-802-8154</p>
      </div>
      <div class="col header-btn nerf-button">
        <img v-on:click="goToNerf" src="images/nerf_button.svg" />
      </div>
    </div>
  </div>
  <div class="div2 fade-in" id="img-div">
    <img src="images/banner_1.png" />
    <img class="banner-img-absolute" src="images/banner_2.svg" />
    <div class="banner-img-block banner-img-absolute top-right">
      <img src="images/banner_3.svg" />
      <div class="top-right-text">
        <span>施設稼働率</span><br />
        <span class="top-right-main-text">{{
          visit_rate >= 0 ? visit_rate + "%" : "取得失敗"
        }}</span>
      </div>
    </div>
    <img class="banner-img-absolute bottom-right" src="images/banner_4.svg" />
  </div>
  <div class="app-container fade-in">
    <a
      href="https://apps.apple.com/jp/app/e-w-c/id6502490439"
      v-on:click="sendViewContentEvent('app_apple')"
      target="_blank"
    >
      <img
        src="images/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg"
        class="store-icon"
      />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.rasis.ewc&pli=1"
      v-on:click="sendViewContentEvent('app_google')"
      target="_blank"
    >
      <img
        src="images/GetItOnGooglePlay_Badge_Web_color_Japanese.png"
        class="store-icon"
      />
    </a>
  </div>
  <TwitterTimeline v-if="false" />
  <div class="div21 fade-in" id="div21" name="div21">
    <div class="sd flex-row margin-bottom-48">
      <div class="sd flex-colunm">
        <div class="sd title full-width">
          <div class="t1">
            <h2 class="text-standard1 text">高知初！</h2>
            <h2 class="text-standard1 text">デジタル技術を活用した</h2>
            <h2 class="text-standard1 text">
              本格的屋内ドラム缶サバゲーフィールド
            </h2>
          </div>
        </div>
        <div class="sd content-t3 height-auto">
          <p class="sd text-standard9 text margin-bottom-24">
            大人も、子供も、熟練者も、初心者も楽しめる「スタンダード」なフィールド
          </p>
          <p class="sd text-standard9 text margin-bottom-24">エアコン完備</p>
          <p class="sd text-standard9 text margin-bottom-24">
            シャワールーム・更衣室完備
          </p>
          <p class="sd text-standard9 text margin-bottom-24">
            駐車場11台〜あります
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="div16 fade-in" id="div16" name="div16">
    <div class="sd content background-white">
      <div class="sd content-t4-s3-sm2 margin-bottom-36 center">
        <!-- 左矢印ボタン -->
        <div class="arrow left" @click="prevSlide">
          <div style="text-align: center">&lt;</div>
        </div>
        <!-- スライドショー -->
        <Carousel
          :items-to-show="isMobile ? 1 : 3"
          :wrap-around="true"
          ref="carousel"
        >
          <Slide v-for="slide in 1" :key="slide">
            <div class="carousel__item">
              <img class="slide-img" src="images/1.jpg" />
            </div>
          </Slide>
          <Slide v-for="slide in 1" :key="slide">
            <div class="carousel__item">
              <img class="slide-img" src="images/2.jpg" />
            </div>
          </Slide>
          <Slide v-for="slide in 1" :key="slide">
            <div class="carousel__item">
              <img class="slide-img" src="images/3.jpg" />
            </div>
          </Slide>
          <Slide v-for="slide in 1" :key="slide">
            <div class="carousel__item">
              <img class="slide-img" src="images/4.jpg" />
            </div>
          </Slide>
          <Slide v-for="slide in 1" :key="slide">
            <div class="carousel__item">
              <img class="slide-img" src="images/5.jpg" />
            </div>
          </Slide>
        </Carousel>
        <!-- 右矢印ボタン -->
        <div class="arrow right" @click="nextSlide">
          <div style="text-align: center">&gt;</div>
        </div>
      </div>
    </div>
  </div>

  <div id="information" class="div3 fade-in" style="position: relative">
    <div class="col" style="width: 30%; float: left">
      <p class="div-title-text">お知らせ</p>
    </div>
    <div class="col" style="width: 70%">
      <ul style="list-style-type: none; width: 70%; margin-top: 70px">
        <li
          v-for="(item, index) in inforItems"
          :key="item.seq"
          :style="{
            'border-top': '2px solid #c3c3c3',
            'text-align': 'left',
            'border-bottom':
              index === inforItems.length - 1 ? '2px solid #c3c3c3' : 'none',
          }"
        >
          <div class="row">
            <p class="black-sub-title-text">{{ item.title }}</p>
          </div>
          <div class="row">
            <p class="info-text" style="">{{ item.date }}</p>
            <p class="info-text" style="">{{ item.content }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div
    id="information_noinfo"
    class="div3_noinfo fade-in"
    style="position: relative"
  >
    <div class="col" style="width: 30%; float: left">
      <p class="div-title-text" style="margin-top: 10px !important">お知らせ</p>
    </div>
    <div class="col" style="width: 70%">
      <div class="row">
        <p style="text-align: left" class="black-sub-title-text">
          現在、新しいお知らせはございません
        </p>
      </div>
    </div>
  </div>
  <div class="div17 fade-in" id="div17" name="div17">
    <div class="sd flex-row margin-bottom-48">
      <div class="sd flex-colunm">
        <div class="sd title full-width">
          <div class="sd t1">
            <h2 class="sd text-standard1 text">E.W.Cについて</h2>
          </div>
          <div class="sd line"></div>
        </div>
        <div class="sd content-t3 height-auto">
          <p class="sd text-standard9 text margin-bottom-24">
            高知初！最新鋭のIT技術を活用し、
          </p>
          <p class="sd text-standard9 text margin-bottom-24">
            未知の世界への冒険が繰り広げられる
          </p>
          <p class="sd text-standard9 text margin-bottom-24">
            屋内ドラム缶サバゲーフィールドへようこそ！
          </p>
        </div>
      </div>
      <div class="about_image">
        <img src="images/about.jpg" />
      </div>
    </div>
    <div class="sd content-t3 height-auto margin-bottom-96">
      <p class="sd text-standard10 text margin-bottom-24">
        当施設は、エキサイティングなゲーム体験を提供するために、戦績データを駆使したシステムを導入しております。
      </p>
      <p class="sd text-standard10 text margin-bottom-24">
        E.W.C屋内ドラム缶サバゲーフィールドは、IT技術とデータ蓄積により、
        サバイバルゲームとしての新しいエンターテイメントを提供します。
      </p>
      <p class="sd text-standard10 text margin-bottom-24">
        魅力的なゲーム体験をお楽しみください！
      </p>
    </div>
  </div>
  <Price :from-home-page="true"></Price>
  <div class="div18 fade-in" id="div18" name="div18">
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">初めての方へ</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div
      class="sd content padding-left-10pct background-white justify-start height-auto"
    >
      <div class="sd content-t3">
        <img
          class="crop-bottom-img margin-bottom-48"
          src="images/forbeginner.jpg"
        />
        <p class="sd text-standard10 text margin-bottom-96 text-align-left">
          E.W.Cをはじめて利用する方は、あらかじめこちらのページをご確認ください。
        </p>
        <button class="content-button" v-on:click="toBeginner()">
          初めての方はこちら
        </button>
      </div>
    </div>
  </div>
  <div class="div19 fade-in" id="div19" name="div19">
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">レギュレーション</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div
      class="sd content padding-left-10pct background-white justify-start height-auto"
    >
      <div class="sd content-t3 height-auto">
        <img
          class="crop-bottom-img margin-bottom-48"
          src="images/regulation.jpg"
        />
        <p class="sd text-standard10 text margin-bottom-24 text-align-left">
          E.W.Cは、E.W.Cのレギュレーションに沿った行動をとっていただける方のみご利用頂けます。
        </p>
        <p class="sd text-standard10 text margin-bottom-96 text-align-left">
          本レギュレーションは、法改正や利用者の安全性を鑑みて改定をおこなう場合があります。ゲームプレイ当日に設定されているレギュレーションに準じて運営をおこないます。
        </p>
        <button class="content-button width-480p" v-on:click="toRegulation()">
          レギュレーションについてはこちら
        </button>
      </div>
    </div>
  </div>
  <div class="div20 fade-in" id="div20" name="div20">
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">フィールドマップ</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content background-white height-auto">
      <div class="sd content-t1 height-auto flex-column justify-start width-80">
        <!-- <div class="sd content-t3 height-auto center flex-row margin-bottom-36">
            <img class="width-100p" src="images/wc_1.png">
            <p class="sd text-standard4 text margin-top-24">E.W.C FIELD MAP</p>
          </div> -->
        <img class="margin-bottom-36" src="images/filed_map_title.svg" />
        <img class="width-100 margin-bottom-96" src="images/filed_map2.jpg" />
        <p class="sd text-standard10 text margin-bottom-96 justify-start">
          ドラム缶をメインにした資材倉庫での戦闘をイメージしたフィールド
        </p>
      </div>
      <div class="sd content-t1 height-auto width-100">
        <img class="width-100 margin-bottom-60" src="images/filed_map1.jpg" />
      </div>
    </div>
  </div>

  <div
    class="div5 fade-in"
    style="background-image: url('images/tokucho_back.jpg')"
  >
    <div class="row div-title">
      <p class="div-title-text" style="color: #fff">E.W.Cの特徴</p>
    </div>
    <ul style="">
      <li
        class="icon_item_list"
        style="background-image: url('images/diamond_div.svg')"
      >
        <div class="row"><P class="des_title">入店・退店管理</P></div>
        <div class="row">
          <p class="des_content">
            マイアプリからQRコードを読み取るだけで入店いただけます。<br />RFIDタグを利用してゲームを管理します
          </p>
        </div>
      </li>
      <li
        class="icon_item_list"
        style="background-image: url('images/note_div.svg')"
      >
        <div class="row"><P class="des_title">データ管理</P></div>
        <div class="row">
          <p class="des_content">
            ゲームフィールドへの入退場、キル/デスデータを蓄積します。<br />蓄積したデータをマイアプリからいつでも確認することができます。
          </p>
        </div>
      </li>
      <li
        class="icon_item_list"
        style="background-image: url('images/fin_div.svg')"
      >
        <div class="row">
          <P class="des_title">場内リアルタイムデータ表示</P>
        </div>
        <div class="row">
          <p class="des_content">
            場内にゲーム結果や個別ランキング表示し、リアルタイムに結果を確認できます。
          </p>
        </div>
      </li>
    </ul>
  </div>

  <div
    class="div7 fade-in"
    id="schedule"
    style="position: relative; z-index: 1"
  >
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">予約情報</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content background-white height-auto">
      <div
        class="sd content-t3 height-auto align-items-center margin-bottom-48"
      >
        <button class="content-button" v-on:click="goToBooking">
          予約情報を見る
        </button>
      </div>
    </div>
  </div>
  <div class="div11 fade-in" id="access">
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">アクセス</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="row map margin-bottom-36">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d587.8282458282619!2d133.4302348953503!3d33.54665396896687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354e2333f4f13689%3A0x602ed6cd0889beaf!2z44CSNzgxLTIxMDYg6auY55-l55yM5ZC-5bed6YOh44GE44Gu55S66aeF5Y2X55S677yR77yU77yR77yV4oiS77yR!5e0!3m2!1sja!2sjp!4v1713771426973!5m2!1sja!2sjp&hl=ja"
        width="100%"
        height="100%"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="sd content background-white height-auto">
      <p class="sd text-standard10 text margin-bottom-24">
        高知西バイパス&emsp;是友降り口より車で5分
      </p>
      <p class="sd text-standard10 text margin-bottom-24">
        JR&emsp;いの駅より徒歩４分
      </p>
      <p class="sd text-standard10 text margin-bottom-24">
        とさでん交通&emsp;いの電停より徒歩7分
      </p>
    </div>
  </div>
  <div
    class="div23 fade-in"
    id="gallery"
    style="position: relative; z-index: 1"
  >
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">フォトギャラリー</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content background-white height-auto">
      <div
        class="sd content-t3 height-auto align-items-center margin-bottom-48"
      >
        <button class="content-button" v-on:click="goToGallery">
          フォトギャラリーを見る
        </button>
      </div>
    </div>
  </div>
  <div
    class="div23 fade-in"
    id="gallery"
    style="position: relative; z-index: 1"
  >
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">グッズ販売</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content background-white height-auto">
      <div
        class="sd content-t3 height-auto align-items-center margin-bottom-48"
      >
        <a href="https://ewc.theshop.jp/" target="_blank"
          ><button class="content-button">グッズ販売はこちら</button></a
        >
      </div>
    </div>
  </div>
  <div class="div22 fade-in" id="div22" name="div22">
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">問い合わせ</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content height-auto">
      <div
        class="sd content-t3 height-auto align-items-center margin-bottom-48"
      >
        <p class="sd text-standard10 text justify-start margin-top-12">
          お名前
        </p>
        <input
          type="text"
          id="i_name"
          name="i_name"
          class="input-standard1"
          placeholder="サバゲ 太郎"
          v-model="i_name"
          maxlength="30"
        />
      </div>
      <div
        class="sd content-t3 height-auto align-items-center margin-bottom-48"
      >
        <p class="sd text-standard10 text justify-start margin-top-12">
          電話番号
        </p>
        <input
          type="text"
          id="i_phone"
          name="i_phone"
          class="input-standard1"
          placeholder="000-1234-5678"
          v-model="i_phone"
          @input="validatePhone"
        />
      </div>
      <div
        class="sd content-t3 height-auto align-items-center margin-bottom-48"
      >
        <p class="sd text-standard10 text justify-start margin-top-12">
          メールアドレス
        </p>
        <input
          type="email"
          id="i_mail"
          name="i_mail"
          class="input-standard1"
          placeholder="abcdef@mail.com"
          v-model="i_mail"
          maxlength="100"
        />
      </div>
      <div
        class="sd content-t3 height-auto align-items-center margin-bottom-60"
      >
        <p class="sd text-standard10 text justify-start margin-top-12">
          お問い合わせ内容
        </p>
        <textarea
          name="i_note"
          id="i_note"
          class="textarea-standard1"
          placeholder="お問い合わせ内容をご記入ください。"
          v-model="i_message"
          maxlength="5000"
        ></textarea>
      </div>
      <div
        class="sd content-t3 height-auto align-items-center margin-bottom-72"
      >
        <button
          class="content-button width-360p"
          :disabled="!isFormValid"
          v-on:click="confirmSend"
        >
          送信する
        </button>
      </div>
      ※3営業日以内に返信をさせていただきます。ご了承ください。
    </div>
  </div>
  <footer id="footer">
    <div class="footer_whitecrow">
      <img src="images/icon_white.svg" alt="RASISグループ" />
      <img src="images/footer_rasis.png" alt="white crow" />
    </div>
    <div class="footer_social">
      <a href="https://x.com/EWC_White_crow" target="_blank"
        ><img src="images/TwitterX_1.png" alt="X"
      /></a>
      <a href="https://www.instagram.com/e.w.c_whitecrow/" target="_blank"
        ><img src="images/instagram_1.png" alt="Instagram"
      /></a>
      <a href="https://www.facebook.com/E.W.C.WhiteCrow" target="_blank"
        ><img src="images/facebook_1.png" alt="Facebook"
      /></a>
      <a href="https://www.youtube.com/@whitecrow.rasis-soft" target="_blank"
        ><img src="images/youtube_1.png" alt="YouTube"
      /></a>
      <a href="https://tiktok.com/@whitecrow_rasissoft" target="_blank"
        ><img src="images/tiktok_1.png" alt="TikTok"
      /></a>
    </div>
  </footer>
  <img
    v-if="showGoTopBtn == true"
    src="images/gotop.png"
    v-on:click="goDiv('img-div')"
    class="gotop-btn"
  />
  <div
    id="loading"
    class="loadingMsg"
    style="background-image: url('images/loading.svg'); display: none"
  ></div>
</template>

<script>
import "v-calendar/style.css";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import ServerInfo from "../server-info";
import APIClient from "@/modules/api";
import {
  generateRandomString,
  getQueryParam,
  navigateToPage,
} from "@/modules/common";
import TwitterTimeline from "@/components/TwitterTimeline.vue";
import Price from "@/components/Price.vue";
import {
  API_STATUS,
  META_PIXEL_INIT_SCRIPT,
  GOOGLE_TAG_CONVERSION_VIEWCONTENT_SCRIPT,
} from "@/modules/constants";
export default {
  name: "MainView",
  components: {
    Carousel,
    Slide,
    TwitterTimeline,
    Price,
  },
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
      visit_rate: 0,
      CalendarItem: [],
      Month: "",
      Year: "",
      Message: "",
      isDropdownVisible: false,
      isMobile: false,
      showGoTopBtn: false,
      baseUrl: ServerInfo.baseUrl,
      uploadFileUrl: ServerInfo.uploadFileUrl,
      beginnerUrl: ServerInfo.beginnerUrl,
      regulationUrl: ServerInfo.regulationUrl,
      mailAddress:
        "nakao@rasis-soft.co.jp,nakatsuji@rasis-soft.co.jp,honjo@rasis-soft.co.jp,otsubo@rasis-soft.co.jp,wageta@rasis-soft.co.jp",
      inforItems: [],
      lastInfoItem: "",
      masks: {
        weekdays: "WWW",
      },
      attributes: [
        {
          key: 1,
          customData: {
            title: "時計",
            class: "bg-yellow text-white",
          },
          dates: new Date(year, month, 22),
        },
        {
          key: 2,
          customData: {
            title: "宝石",
            class: "bg-yellow text-white",
          },
          dates: new Date(year, month, 23),
        },
      ],
      i_name: "",
      i_phone: "",
      i_mail: "",
      i_message: "",
      twitterUrl: "https://twitter.com/whitecrow_rasis",
      instaUrl: "https://www.instagram.com/whitecrow_rasissoft/",
      fbUrl: "https://www.facebook.com/whitecrow.rasissoft/",
      ytUrl: "https://www.youtube.com/@whitecrow.rasis-soft",
      tiktokUrl: "https://www.tiktok.com/@whitecrow_rasissoft",

      pageViewId: null,
      viewContentId: null,
      scheduleId: null,

      fbclid: null,
    };
  },
  computed: {
    isFormValid() {
      return this.i_name && this.i_phone && this.i_mail && this.i_message;
    },
  },
  methods: {
    handleOutsideClick(event) {
      const dropdownMenu = document.getElementById("dropdownMenu");
      const dropdownButton = document.getElementById("dropdownButton");
      const target = event.target;

      if (!dropdownMenu) return;

      if (!dropdownButton.contains(target)) {
        this.isDropdownVisible = false;
      }
    },
    validatePhone() {
      this.i_phone = this.i_phone.replace(/[^\d-]/g, "");
    },
    confirmSend() {
      if (confirm("送信してよろしいですか？")) {
        this.sendMail();
      }
    },
    prevSlide() {
      this.$refs.carousel.prev();
    },
    nextSlide() {
      this.$refs.carousel.next();
    },
    async sendMail() {
      const response = await APIClient.post("send_mail.php", {
        name: this.i_name,
        mail: this.i_mail,
        tel: this.i_phone,
        content: this.i_message,
      });

      if (response.Status == API_STATUS.NORMAL) {
        alert("メールの送信が完了しました。");
        this.resetForm();
      } else {
        alert(
          "エラーでメールの送信に失敗しました。お手数ですが時間を置いて再度お試しいただくか、お急ぎの場合は画面上部の電話番号までお電話をお願い致します。"
        );
      }
    },
    resetForm() {
      this.i_name = "";
      this.i_phone = "";
      this.i_mail = "";
      this.i_message = "";
    },
    goDiv(id) {
      document.getElementById(id).scrollIntoView();
    },
    toBeginner() {
      navigateToPage("beginner", true);
      // イベント送信
      this.sendViewContentEvent("beginner");
    },
    toRegulation() {
      navigateToPage("regulation", true);
      // イベント送信
      this.sendViewContentEvent("regulation");
    },
    calTopOffset() {
      var main = document.getElementById("div17");
      if (!main) {
        this.showGoTopBtn = false;
        return;
      }
      var rect = main.getBoundingClientRect();
      var elemTop = rect.top;
      if (elemTop < 0) this.showGoTopBtn = true;
      else this.showGoTopBtn = false;
    },
    updateCalendarItem(month, year, calendarItem) {
      if (calendarItem == null || Array.isArray(calendarItem))
        this.CalendarItem = calendarItem;
      else {
        this.CalendarItem = [];
        this.CalendarItem.push(calendarItem);
      }

      this.Month = month + 1;
      this.Year = year;
    },
    goSocial(url) {
      window.open(url);
    },
    goToBooking() {
      // イベント送信
      this.sendViewContentEvent("calendar");
      // 新しいタブでページを開きます
      navigateToPage("calendar", true);
    },
    goToLogin() {
      // 新しいタブでページを開きます
      navigateToPage("login-my-page", true);

      // イベント送信
      this.sendViewContentEvent("login");
    },
    goToNerf() {
      // 新しいタブでページを開きます
      navigateToPage("nerf", true);
      // イベント送信
      this.sendViewContentEvent("nerf");
    },
    goToGallery() {
      // 新しいタブでページを開きます
      navigateToPage("photo-gallery", true);

      // イベント送信
      this.sendViewContentEvent("gallery");
    },
    goToiOSApp() {
      // リダイレクト先のURLを定義します
      const url = "";
      // 新しいタブでページを開きます
      window.open(url, "_blank");

      // イベント送信
      this.sendViewContentEvent("app_ios");
    },
    checkIfMobile() {
      // ウィンドウの幅が600px以下の場合はモバイルデバイスとみなす
      this.isMobile = window.innerWidth <= 600;
    },
    async getVisitRate() {
      try {
        const response = await APIClient.get("get_occupancy_rate.php");
        if (response.Status == API_STATUS.NORMAL) {
          this.visit_rate = response.Data.visit_rate;
        } else {
          console.error("稼働率の取得に失敗しました。");
          this.visit_rate = -1;
        }
      } catch (error) {
        console.error("稼働率の取得に失敗しました。", error);
        this.visit_rate = -1;
      }
    },
    async getInformation() {
      try {
        const response = await APIClient.get("get_info_list.php");
        if (response.Status == API_STATUS.NORMAL) {
          this.inforItems = response.Data.map((item) => ({
            title: item.vtitle,
            content: item.vinfo,
            date: item.dstart,
          }));
          if (this.inforItems.length > 0) {
            document.getElementById("information_noinfo").style.display =
              "none";
          } else {
            document.getElementById("information").style.display = "none";
            document.getElementById("information_noinfo").style.display =
              "block";
          }
        } else {
          console.error("お知らせ情報の取得に失敗しました。");
          document.getElementById("information").style.display = "none";
          document.getElementById("information_noinfo").style.display = "block";
        }
      } catch (error) {
        console.error("お知らせ情報の取得に失敗しました。", error);
        document.getElementById("information").style.display = "none";
        document.getElementById("information_noinfo").style.display = "block";
      }
    },
    beforeDestroy() {
      // コンポーネントが破棄される前にイベントリスナーを削除
      window.removeEventListener("click", this.handleOutsideClick);
    },

    // ページビューイベント送信
    async sendPageViewEvent() {
      // Pixel
      window.fbq(
        "track",
        "PageView",
        {
          content_type: "reserve",
        },
        { eventID: this.pageViewId }
      );

      // コンバージョンAPI
      try {
        const response = await APIClient.get("post_conversion.php", {
          eventName: "PageView",
          contentType: "reserve",
          eventSourceUrl: window.location.href,
          eventId: this.pageViewId,
          fbclid: this.fbclid,
        });
        if (response.Status != API_STATUS.NORMAL) {
          console.error("コンバージョンAPIの送信に失敗");
        }
      } catch (error) {
        console.error("コンバージョンAPIの送信に失敗", error);
      }
    },
    // コンテンツビューイベント送信
    async sendViewContentEvent(name) {
      // Pixel
      window.fbq(
        "track",
        "ViewContent",
        {
          content_name: name,
          content_category: "ewc_button_click",
          content_type: "home",
        },
        { eventID: this.viewContentId }
      );

      // コンバージョンAPI
      try {
        const response = await APIClient.get("post_conversion.php", {
          eventName: "ViewContent",
          contentName: name,
          contentCategory: "ewc_button_click",
          contentType: "home",
          eventSourceUrl: window.location.href,
          eventId: this.viewContentId,
          fbclid: this.fbclid,
        });
        if (response.Status != API_STATUS.NORMAL) {
          console.error("コンバージョンAPIの送信に失敗");
        }
      } catch (error) {
        console.error("コンバージョンAPIの送信に失敗", error);
      }

      // Googleタグ
      window.gtag_report_conversion();
    },

    handleScroll() {
      const elements = document.querySelectorAll(".fade-in");
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          // 要素が画面内に入ったらフェードインのクラスを付与する
          el.classList.add("visible");
        } else {
          // 要素が画面外に出たらフェードインのクラスを一旦削除する
          el.classList.remove("visible");
        }
      });
    },
  },
  mounted() {
    this.getVisitRate();

    this.getInformation();
    window.addEventListener("scroll", this.calTopOffset);

    // コンポーネントがマウントされた後、ウィンドウの幅を確認し、モバイルデバイスかどうかを設定する
    window.addEventListener("resize", this.checkIfMobile);
    this.checkIfMobile(); // 初回の判定を実行

    window.addEventListener("click", this.handleOutsideClick);

    // 初期ロード時に要素が表示されているかチェック
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();

    // "file"というidの要素が存在するかチェック
    const fileInput = document.getElementById("file");
    if (fileInput) {
      // 存在する場合はvalueをnullに設定
      fileInput.value = null;
    }

    // Metaピクセル用のイベントID発行
    this.pageViewId = "topview_" + generateRandomString(9);
    this.viewContentId = "topclick_" + generateRandomString(9);

    // Metaピクセルのスクリプトをロードする
    const metaPixelScript = document.createElement("script");
    metaPixelScript.innerHTML = META_PIXEL_INIT_SCRIPT;
    document.head.appendChild(metaPixelScript);

    // Googleタグのスクリプトをロードする
    const googleTagScript = document.createElement("script");
    googleTagScript.innerHTML = GOOGLE_TAG_CONVERSION_VIEWCONTENT_SCRIPT;
    document.head.appendChild(googleTagScript);

    // クリックIDをロードする
    this.fbclid = localStorage.getItem("fbclid");
    const newFbclid = getQueryParam("fbclid");
    if (newFbclid) {
      this.fbclid = newFbclid;
      localStorage.setItem("fbclid", this.fbclid);
    }

    // ページビューイベントを送信
    setTimeout(() => {
      this.sendPageViewEvent();
    }, 1);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
#app {
  text-align: center;
  background-color: white;
}
.div1 {
  background-color: #fff;
  height: 80px;
  position: fixed;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  top: 0px;
  z-index: 10;
}
.page-header-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.div2 {
  background-color: #fff;
  max-height: 791px;
  position: relative;
  display: inline-block;
  margin-top: 100px;
}

.div2 img {
  max-height: 791px;
}
.div3 {
  background-color: #fff;
  padding-bottom: 100px;
}

.div3 ul {
  max-height: 600px !important;
  height: 100%;
  overflow-y: scroll;
}

.div3::-webkit-scrollbar {
  width: 2px;
}

.div3::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.div3::-webkit-scrollbar-thumb {
  background-image: linear-gradient(-45deg, #6a5af9, #d66efd);
  border-radius: 5px;
}

.div4 {
  padding-top: 30px;
  background-color: #f0f0f0;
  height: 1860px;
}
.div5 {
  height: 935px;
  background-repeat: no-repeat;
  background-size: cover;
}
.div6 {
  background-color: #f0f0f0;
  height: 830px;
}
.div7 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.div8 {
  background-color: #f0f0f0;
  height: 860px;
}
.div9 {
  background-color: #fff;
  height: 2880px;
}
.div10 {
  background-color: #fff;
}
.div11 {
  background-color: #fff;
  height: 992px;
  text-align: center;
}
.div12 {
  background-color: #000;
  display: flex;
}
.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 78px;
  --day-height: 60px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
}
.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  /* padding: 10px 0; */
}
.custom-calendar.vc-container .vc-weeks {
  padding: 0;
}
.custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
.custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px 5px;
  text-align: center;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: white;
}
.custom-calendar.vc-container .vc-day.weekday-1,
.custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}
.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}
.custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: var(--day-border-highlight);
}
.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}
.custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}
.bg-yellow {
  background-color: yellow;
  height: 30px;
  margin: 0px;
}

.text-white {
  color: #fff;
}
.col {
  display: inline-block;
  height: 100%;
}
.row {
  display: block;
  width: 100%;
}

table,
th,
td {
  border: 1px solid #c3c3c3;
  border-collapse: collapse;
}
.form-title {
  width: 30%;
}
.mark {
  margin-left: 2.5%;
  width: 5%;
  margin-right: 2.5%;
}
.s1 {
  width: 60%;
}
.s2 {
  width: 29%;
}
.s3 {
  width: 18%;
}
.s4 {
  width: 10%;
}
.s5 {
  width: 30px;
}
.s35 {
  width: 35%;
}
.s15 {
  width: 15%;
}
.s50 {
  width: 50%;
}
.mark-text {
  background-color: #c30101;
  color: #fff;
  margin: auto;
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
  text-align: center;
}
.title-text {
  text-align: left;
  color: #000;
  font-size: 19px;
}
.input-textbox {
  height: 55px;
  font-size: 20px;
  width: 100%;
  border: none;
  border-radius: 5px;
  border: 0.5px solid #c3c3c3;
  margin-top: 10px;
  outline: none;
}
.input-textbox-multiline {
  font-size: 20px;
  height: 170px;
  width: 100%;
  border: none;
  border-radius: 5px;
  border: 0.5px solid #c3c3c3;
  margin-top: 10px;
  outline: none;
}
.doui-btn {
  background-image: linear-gradient(to right, #e4d352, #edde6a);
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 25px;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  margin-top: 0px;
  cursor: pointer;
}
.line-btn {
  background-color: #54bf4b;
  height: 70px;
  width: 100%;
  border: none;
  border-radius: 15px;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  color: #fff;
}

.title_text {
  font-weight: bold;
  font-size: 40px;
}
.big_title_text {
  font-weight: bold;
  font-size: 50px;
  padding-top: 90px;
  color: #fff;
}
.icon_item_list {
  height: 198px;
}
.img_btn {
  cursor: pointer;
}
.des_title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 225px;
  padding-top: 40px;
}
.des_content {
  text-align: left;
  font-size: 20px;
  margin-top: 0;
  margin-left: 225px;
  padding-top: 15px;
}

.banner-img-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: auto;
}

.div2 .top-right {
  transform: translate(0, 0);
  top: 3%;
  right: 9%;
  width: 15%;
  left: auto;
}

.div2 .top-right-text {
  position: absolute;
  top: 20%;
  left: 25%;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 25px;
  font-weight: bold;
}

.div2 .top-right-main-text {
  font-size: 50px;
}
.div2 .bottom-right {
  transform: translate(0, 0);
  top: auto;
  bottom: 45%;
  right: 5%;
  width: 15%;
  left: auto;
}

.div2 .banner-img-block {
  display: inline-block;
}

marquee {
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%; /* コンテナ全体の幅に合わせる */
  color: white; /* テキストの色 */
  font-size: 100px;
  font-weight: bold;
  color: #a1ea3d;
}

.about_image img {
  max-width: 450px;
  padding-left: 50%;
}

.sd.content-t2.rental-image {
  background: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2列に設定 */
  gap: 10px; /* 画像間のスペースを設定 */
}

.rental-image-container {
  flex-direction: column;
  display: grid;
  grid-template-rows: auto 1fr; /* テキストと画像の高さを均等に配置 */
  align-items: center;
  text-align: center;
}

.rental-image-text {
  align-self: start; /* テキストを上部に配置 */
  margin-bottom: 5px; /* テキストと画像の間にスペースを追加 */
}

.sd.content-t2.rental-image img {
  width: 100%; /* 各画像の幅を100%に設定 */
  height: auto; /* 自動で高さを調整 */
  align-self: start; /* 画像を上部に配置 */
}

.app-container {
  display: none;
  justify-content: center;
  align-items: center;
  height: 50px; /* コンテナの高さを設定 */
}

.store-icon {
  width: 100px; /* アイコンの幅を調整 */
  height: auto;
  margin: 0 10px; /* アイコン間のスペースを調整 */
}

.fade-in {
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}
</style>
