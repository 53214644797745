<template>
  <div class="mypage">
    <div v-if="initialized" class="container">
      <header>
        <div class="header-elements">
          <h4 class="back" @click="back">＜ 戻る</h4>
          <label>{{ vid }}：{{ vplynm }}</label>
        </div>
      </header>
      <div class="view-points-container">
        <h3 class="title">[ポイント確認]</h3>

        <div v-if="!loadedData" class="form-group">
          <p style="color: red">
            有効期限内のポイントもしくは年パスがありません。
          </p>
        </div>

        <div
          v-else-if="loadedData && loadedData.dstdt_money"
          class="form-group"
        >
          <label for="points" class="label-left">ポイント残高</label>
          <input
            type="text"
            id="points"
            v-model="loadedData.nmoney"
            style="text-align: right"
            :class="{ disabled: true }"
            :readonly="true"
            required
          />
        </div>
        <div
          v-if="loadedData && loadedData.dstdt_money"
          class="form-group horizontal"
        >
          <label for="period-money" class="label-left">有効期限</label>
          <div class="input-group">
            <input
              type="text"
              id="start-period-money"
              v-model="loadedData.dstdt_money"
              class="form-control small-input"
              style="width: 100px"
              readonly
            />
            <span class="separator"> ～ </span>
            <input
              type="text"
              id="end-period-money"
              v-model="loadedData.deddt_money"
              class="form-control small-input"
              style="width: 100px"
              readonly
            />
          </div>
          <br /><br />
        </div>
        <div
          v-if="loadedData && loadedData.dstdt_subscription"
          class="form-group"
        >
          <p style="color: yellow">年パス利用中</p>
        </div>
        <div
          v-if="loadedData && loadedData.dstdt_subscription"
          class="form-group horizontal"
        >
          <label for="period-sub" class="label-left">有効期限</label>
          <div class="input-group">
            <input
              type="text"
              id="start-period-sub"
              v-model="loadedData.dstdt_subscription"
              class="form-control small-input"
              style="width: 100px"
              readonly
            />
            <span class="separator"> ～ </span>
            <input
              type="text"
              id="end-period-sub"
              v-model="loadedData.deddt_subscription"
              class="form-control small-input"
              style="width: 100px"
              readonly
            />
          </div>
        </div>

        <br />
        <div class="button-group">
          <button type="button" @click="back" class="back-button">戻る</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { getQueryParam, checkLoginInfo, logout } from "@/modules/common";
import { API_STATUS } from "@/modules/constants";

export default {
  name: "viewPoints",
  data() {
    return {
      loadedData: null,
      vid: null,
      vmemnm: null,
      vplynm: null,
      initialized: false,
    };
  },
  async mounted() {
    if (getQueryParam("backpress")) {
      this.back();
      return;
    }
    await this.initialize();
    this.initialized = true;
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納し、会員情報を取得して入力欄に格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.vid = sessionUserId;
        await this.getMemberInfo(sessionUserId, login_hash, login_time);
        await this.getDetailInfo(sessionUserId, login_hash, login_time);
      } else {
        logout();
      }
    },
    async getDetailInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_money_detail.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.loadedData = response.Data;
        } else {
          console.error("ポイント情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("ポイント情報の取得に失敗しました。", error);
      }
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.vmemnm = response.Data.vmemnm;
          this.vplynm = response.Data.vplynm;
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
    // 戻る
    back() {
      this.$router.push("/my-page");
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.view-points-container {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
  width: 80%;
  color: white;
}
.back {
  color: white;
  text-align: left;
  cursor: pointer;
  width: fit-content;
}

.back:hover {
  border-bottom: 1px solid #fff; /* 下線を追加 */
}

button {
  max-width: 300px;
  width: 95%;
}

.edit-button {
  background-color: rgb(179, 228, 197);
  margin-bottom: 50px;
}

.cancel-button {
  background-color: rgb(180, 180, 180);
  margin-bottom: 50px;
}

.warning {
  color: white;
  font-size: 15px;
  font-weight: bold;
}

input.disabled,
textarea.disabled,
select.disabled {
  background-color: #f0f0f0;
  color: #333;
  pointer-events: none;
  border: 1px solid #ccc;
}

input[readonly],
textarea[readonly],
select[readonly] {
  background-color: #cfcece;
  color: #333;
  border: 1px solid #ccc;
}

.tentative {
  color: #e99802;
  font-size: 18px;
  font-weight: bold;
}

.confirmed {
  color: rgb(0, 255, 0);
  font-size: 18px;
  font-weight: bold;
}
</style>
