// サーバーのベース URL
export const BASE_URL = process.env.VUE_APP_BASE_URL || "/";

// API サーバーのベース URL
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL || "/api/";

// 最大予約人数
export const MAX_PEOPLE = 30;

// レーダーチャートMAX値
export const MAX_CHART_TICKS = 5;

/** APIのステータス */
export const API_STATUS = {
  NORMAL: 0, // 正常
  NODATA: 1, // データなし
  ERROR: -1, // エラー
  LOGINERROR: -2, // ログインエラー
  ALERT: -3, // 警告アラート
};

/** 画像ファイル名指定 */
export const IMAGE_FILE_NAMES_PER_TITLES = {
  "練習会": "practice_match_reserve.png",
  "本大会": "main_match_reserve.png",
};

// ハッシュキー
export const HASH_KEY = "EWCLRWC";

// Metaピクセル開始スクリプト
export const META_PIXEL_INIT_SCRIPT = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1082936892713276');    
  `;

export const GOOGLE_TAG_CONVERSION_VIEWCONTENT_SCRIPT = `
    function gtag_report_conversion(url) {
        var callback = function () {
            if (typeof(url) != 'undefined') {
            window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11286367358/eHfYCMX5wbUZEP6Y4YUq',
            'event_callback': callback
        });
        return false;
    }  
  `;

export const GOOGLE_TAG_CONVERSION_SCHEDULE_SCRIPT = `
    function gtag_report_conversion(email, phone, url) {
        var callback = function () {
            if (typeof(url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11286367358/nuD-CJ7PvLUZEP6Y4YUq',
            'event_callback': callback
        });
        
        var userData = {};
        if (email) {
            userData.email = email;
        }
        if (phone) {
            userData.phone_number = phone;
        }
        
        gtag('set', 'user_data', userData);
        
        return false;
    }
`;

export const GOOGLE_TAG_CONVERSION_REGIST_SCRIPT = `
    function gtag_report_conversion(email, phone, url) {
        var callback = function () {
            if (typeof(url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11286367358/nBeQCP37vLUZEP6Y4YUq',
            'event_callback': callback
        });
        gtag('set', 'user_data', {
            'email': email,
            'phone_number': phone            
        });
        return false;
    }
`;
