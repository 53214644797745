<template>
  <div class="div14">
    <div class="sd content background-white height-auto">
      <div class="sd content-t3 height-auto width-80">
        <img
          class="crop-bottom-img margin-bottom-96"
          src="images/forbeginner.jpg"
        />
        <p class="sd text-standard4 text font-1em margin-left-0">必要なもの</p>
        <div class="full-line-gray margin-bottom-36 margin-top-12"></div>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          初回利用の際に、「利用申込用紙兼誓約書」への記載が必要です。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          「利用申込用紙兼誓約書」ご提出時に、身分証明書（免許証、健康保険証、住所・氏名・生年月日が分かる書類、学生の方は学生証）を確認させていただきます。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60"
        >
          ※18歳未満の方については保護者の同意書・ご署名が必要となります。
        </p>
        <p class="sd text-standard4 text font-1em margin-left-0">
          予約に関して
        </p>
        <div class="full-line-gray margin-bottom-36 margin-top-12"></div>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          参加予約は予約フォームから可能です。参加当日、定員に空きがある場合は当日予約なしでの参加も可能です。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          参加者の安全管理と防犯上の理由から、見学のみ・付き添いの方も受付でご来場の確認をさせていただきます。ご協力をよろしくお願いします。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          受付時にRFIDのタグを貸出を行い、フィールドへの入退場確認はRFIDタグを用いて行います。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60"
        >
          ゲームアナウンスは自動アナウンスにて行います。
        </p>
        <p class="sd text-standard4 text font-1em margin-left-0">
          駐車場のご利用に関して
        </p>
        <div class="full-line-gray margin-bottom-36 margin-top-12"></div>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          駐車場以外の場所への駐車・フィールド周辺などへの路上駐車は、近隣の皆様に大変ご迷惑がかかりますのでご遠慮下さい。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60"
        >
          ご来場者の違法駐車・無断駐車を発見した場合は、フィールドの利用をお断りさせていただくことがございます。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60"
        >
          何卒ご理解とご協力の程よろしくお願いいたします。
        </p>
        <button class="content-button width-480p" v-on:click="toRegulation()">
          レギュレーションについてはこちら
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { navigateToPage } from "@/modules/common";
export default {
  name: "BeginnerGuide",
  methods: {
    goback() {
      this.$router.push("/");
    },
    toRegulation() {
      navigateToPage("regulation", true);
    },
  },
  mounted() {},
};
</script>
